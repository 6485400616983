/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import get from 'lodash/get';
import { useQuery } from 'utils/react-query';
import Image from 'components/uiLibrary/Image';
import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';

import queries from 'containers/Globals/queries';

import usePageContext from 'utils/hooks/usePageContext';

import { Trans, useTranslation } from 'src/i18n';
import { TP, BASE_PAGE_ROUTES } from 'constants/index';
import BannerDetailsDrawer from '../../ConversionFlowBanners/BannerDetailsDrawer';

import classes from './Casting.module.scss';

const Casting = ({ config }) => {
  const { t } = useTranslation('NS_CONVERSION_FLOW');
  const { data } = useQuery(queries.getHomeNumbers());
  const [isOpen, setIsOpen] = useState(false);
  const { navigate, permissions } = usePageContext();
  const { isLoggedIn } = permissions;

  const prefInfo = { number: get(data, 'data.performances.total'), info: 'm_PERF' };
  const artistInfo = { number: get(data, 'data.artists.total'), info: 'm_ARTISTS' };

  const handleOnClick = () => {
    if (!isLoggedIn) {
      setIsOpen(true);
      return;
    }

    if (permissions?.castingToolPermissions?.hasAccess) {
      const linkProps = navigate.getLinkProps({
        baseRoute: BASE_PAGE_ROUTES.CASTING,
        onlyLinkProps: true,
      });
      navigate.to(linkProps);
    } else {
      setIsOpen(true);
    }
  };

  return (
    <>
      <div className={classes.castingBanner} onClick={handleOnClick}>
        <div className={classes.castingBanner__logoBlock}>
          <Image
            src="https://public.operabase.com/images/conversionFlow/logo_20240620T105126322Z.webp"
            alt="Operabase Home"
            title="Operabase Home"
            lazy={false}
            disableCloudinary
            disableNextImage
            className={classes.logo}
            width={120}
          />
          <Typography size={12} className={classes.title} color="secondary">
            {t(`${TP}.LP_SINCE`)}
          </Typography>
        </div>
        <div className={classes.castingBanner__bgBlurredShade}></div>
        <div className={classes.castingBanner__image}>
          <Image
            src="https://public.operabase.com/images/conversionFlow/casting_main_banner_1_en_20240704T070604865Z.webp"
            alt="Operabase Devices"
            title="Operabase Devices"
            lazy={false}
            width={376}
            height={200}
            className={classes.drawerBlock__left_devices}
            disableCloudinary
            disableNextImage
          />
        </div>
        <div className={classes.castingBanner__textArea}>
          <div className={classes.castingBanner__textArea_listRow}>
            <Trans
              i18nKey={`${TP}.FN_CONVERSION_CASTING_BANNER_ITEMS`}
              components={{
                ul: <Typography variant="ul" className={classes.list} />,
                li: <Typography variant="li" className={classes.listItems} />,
              }}
              ns="NS_CONVERSION_FLOW"
            />
          </div>
          <div className={classes.castingBanner__textArea_mainBlock}>
            <Typography className={classes.title}>{t(`${TP}.FN_CASTING`)}?</Typography>
            <div className={classes.subTextBlock}>
              <Typography className={classes.subTextBlock__subTitle}>
                <Trans
                  i18nKey={`${TP}.${config.subTitle}`}
                  components={{
                    br: <br />,
                    artistCount: artistInfo?.number,
                    prefCount: prefInfo?.number,
                  }}
                  ns="NS_CONVERSION_FLOW"
                />
              </Typography>
              {config.renderArtistInsights && (
                <Typography className={classes.subTextBlock__otherText} weight="medium">
                  <ul>
                    <li>
                      {artistInfo?.number} {t(`${TP}.${artistInfo?.info}`)}
                    </li>
                    <li>
                      {prefInfo?.number} {t(`${TP}.${prefInfo?.info}`)}
                    </li>
                  </ul>
                </Typography>
              )}
              <div className={classes.subTextBlock__listRow}>
                <Trans
                  i18nKey={`${TP}.FN_CONVERSION_CASTING_BANNER_ITEMS`}
                  components={{
                    ul: <Typography variant="ul" className={classes.list} />,
                    li: <Typography variant="li" className={classes.listItems} />,
                  }}
                  ns="NS_CONVERSION_FLOW"
                />
              </div>
              <LinkButton
                rightIcon={<SpriteIcon icon="chevron_right" />}
                styles={{ root: classes.subTextBlock__tryNowBtn }}
                variant="text"
              >
                {t(`${TP}.FN_CONVERSION_TRY_NOW`)}
              </LinkButton>
            </div>
          </div>
        </div>
      </div>
      {isOpen && <BannerDetailsDrawer isOpen={isOpen} type="casting" onClose={() => setIsOpen(false)} showLoginText />}
    </>
  );
};

export default Casting;
